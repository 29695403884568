import { Tab, Tabs } from "@blueprintjs/core";
import React, { FunctionComponent, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import {
  ActivityTagEnum,
  ActivityTypeEnum,
  UserNotesType,
} from "../../../types";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import { generatePath, useHistory, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import ActivityResourcesTab from "../members/tabs/resources-tab/ActiveResourcesTabs";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";

type OwnProps = {};

type Props = OwnProps;

const LearningPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { tabId } = useParams<{ tabId?: string }>();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const history = useHistory();

  // const selectedTabId = useMemo(() => {
  //   return history.push(
  //               generatePath(AuthRoutes.YourPlanLearning, {
  //                   workspace: "help",
  //               } as any)
  //           );
  // });

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.help_center" })}</title>
      </Helmet>

      <div className="teampage_container">
        <WorkspaceHeader
          title={intl.formatMessage({ id: "app.titles.help_center" })}
          userNotesType={
            isDistrictActivePlan
              ? UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE
              : UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE
          }
        />

        <div className="teampage_container__tab">
          <ActivityResourcesTab
            activityTag={ActivityTagEnum.LEARNING_NETWORK_EVENTS}
            userNotesType={
              isDistrictActivePlan
                ? UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE
                : UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE
            }
            filterFn={(a) => {
              return a.tag === ActivityTagEnum.LEARNING_NETWORK_EVENTS;
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LearningPage;