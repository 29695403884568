import { Tab, Tabs } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { generatePath, useHistory, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import { TeamPlanResourcesTabEnum, UserNotesType } from "../../../types";
import ActivityResourcesTab from "../members/tabs/resources-tab/ActiveResourcesTabs";
import WorkspaceStrategies from "../strategies/WorkspaceStrategies";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import UploadedFiles from "./tabs/UploadedFiles";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";
import StrategiesList from "../../pages/strategic-plan/strategies/strategies-list/StrategiesList";
import StrategiesResourceList from "../../pages/strategic-plan/strategies/strategies-list/StrategiesResourceList";

import StrategicDisclaimer from "../../pages/strategic-plan/strategic-disclaimer/StrategicDisclaimer";

type OwnProps = {};

type Props = OwnProps;

const TeamPlanResources: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const history = useHistory();

  const { tabId } = useParams<{ tabId?: string }>();

  const enabledStrategicPlan = useSelector(
    (s) => s.plans.activePlan?.district?.enabled_strategic_plan
  );

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  // const selectedTabId: TeamPlanResourcesTabEnum = useMemo(() => {
  //   if (tabId) {
  //     if (enabledStrategicPlan) {
  //       return tabId as TeamPlanResourcesTabEnum;
  //     } else {
  //       // if (tabId === TeamPlanResourcesTabEnum.FullStrategyLibrary) {
  //       //   return TeamPlanResourcesTabEnum.Resources;
  //       // } 
  //       // else {
  //         return tabId as TeamPlanResourcesTabEnum;
  //       // }
  //     }
  //   } else {
  //     return enabledStrategicPlan
  //       ? TeamPlanResourcesTabEnum.FullStrategyLibrary
  //       : TeamPlanResourcesTabEnum.Resources;
  //   }
  // }, [tabId, enabledStrategicPlan]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.resources" })}</title>
      </Helmet>

      <div className="teampage_container">
        <WorkspaceHeader
          title={intl.formatMessage({ id: "app.titles.resources" })}
          userNotesType={
            isDistrictActivePlan
              ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
              : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
          }
        />

        <div className="disclaimer-top-rigth">
          {/* <StrategicDisclaimer /> */}
        </div>
        <div className="teampage_container__tab">
          <Tabs
            onChange={(tabId) => {
              history.push(
                generatePath(AuthRoutes.YourPlanResources, {
                  workspace: "resources",
                  tabId: tabId,
                } as any)
              );
            }}
            renderActiveTabPanelOnly
          >
            {/* <Tab
            id={TeamPlanResourcesTabEnum.FullStrategyLibrary}
            title={intl.formatMessage({
              id: "app.strategies.strategy-library",
            })}
            panel={<StrategiesResourceList />}
            hidden={!enabledStrategicPlan}
          /> */}
            <Tab
              id={TeamPlanResourcesTabEnum.TeamDevelopment}
              title={intl.formatMessage({ id: "app.titles.sctp-resources" })}
              panel={
                <ActivityResourcesTab
                  userNotesType={
                    isDistrictActivePlan
                      ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
                      : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
                  }
                />
              }
            />
            <Tab
              id={TeamPlanResourcesTabEnum.Resources}
              title={intl.formatMessage({ id: "app.titles.uploaded-materials" })}
              panel={<UploadedFiles />}
            />
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default TeamPlanResources;