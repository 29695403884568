import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import Sticky from "react-sticky-el";
import { isDistrictPlan } from "../../store/plans/selectors";
import TeamPlanDashboard from "../team-plan/dashboard/TeamPlanDashboard";
import CollectDataPage from "../team-plan/data/CollectDataPage";
import GoalsPage from "../team-plan/goals/GoalsPage";
import LearningPage from "../team-plan/learning/LearningPage";
import TeamingPage from "../team-plan/members/TeamingPage";
import StrategicPlan from "./strategic-plan/StrategicPlan";
import NeedsPage from "../team-plan/needs-and-goals/NeedsPage";
import NotesDialog from "../team-plan/notes/notes-dialog/NotesDialog";
import TeamPlanResources from "../team-plan/resources/TeamPlanResources";
import EditSurveyQuestions from "./EditSurveyQuestions";
import PlanReports from "./PlanReports";
import ShareFeedbackDialog from "../team-plan/workspace-header/dialogs/ShareFeedbackDialog";
import DistrictDetails from "./DistrictDetails";
import AppWelcomePage from "./AppWelcomePage";
import { PlanWorkspaces } from "../../types";
import {
  TEAM_PLAN_NAVIGATION_WIDTH,
  TEAM_PLAN_PAGES_PADDING,
} from "../../constants/constants";
import useNotesDialogOpenBySearchParams from "../../helpers/hooks/useNotesDialogOpenBySearchParams";
import ViewActivityContentDialog from "../team-plan/members/activity-video-dialog/ViewActivityContentDialog";
import ManageDeletedData from "../team-plan/manage-deleted-data/ManageDeletedData";
import MenuList from "../common/navigation/menu-list/MenuList";
import AddStrategyForm from "./strategic-plan/strategies/add-strategy/add-strategy-form/AddStrategyForm";
import CustomStrategy from "./strategic-plan/strategies/add-strategy/add-strategy-form/custom-strategy/CustomStrategy";
import TopBar from "../common/top-bar/top-bar";
import GetStartedCheckList from "../onboarding-tour/GetStartedCheckList";
import DistrictSetupWidget from "../team-plan/dashboard/district-setup-widget/DistrictSetupWidget";
import CongratulationImg from "../../static/images/congratulationImg.png";
import SciWritingComponents from "./strategic-plan/strategies/edit-core-components/sci-writing/CreateNotes/SciWritingComponents";
import { SciMiddleContent } from "./strategic-plan/strategies/edit-core-components/sci-writing/PublishedNotes/SciMiddleContent";

import SciWritingComponentsPublishedNote from "./strategic-plan/strategies/edit-core-components/sci-writing/PublishedNotes/SciWritingComponents";
import { NotesPage } from "../team-plan/notes/new-layout-notes/NotesPage";
import StrategyLibrary from "../team-plan/strategy-libary/StrategyLibrary";

type OwnProps = {};

type Props = OwnProps;

const DISTRICT_PLAN_PERMITTED_WORKSPACES = [
  PlanWorkspaces.Dashboard,
  PlanWorkspaces.Team,
  PlanWorkspaces.Resources,
  PlanWorkspaces.StrategyLibrary,
  PlanWorkspaces.Help,
  PlanWorkspaces.MyDistrict,
  PlanWorkspaces.Welcome,
  PlanWorkspaces.ManageDeletedData,
  PlanWorkspaces.Report,
  PlanWorkspaces.Data,
  PlanWorkspaces.Goals,
  PlanWorkspaces.SCIWriting,
  PlanWorkspaces.PublishedNotes,
];

const PlanPage: FunctionComponent<Props> = (props) => {
  const { workspace, tabId } = useParams<{
    workspace?: string;
    tabId?: string;
  }>();
  const intl = useIntl();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  useNotesDialogOpenBySearchParams();

  const history = useHistory();

  const { setupCompleted } = useSelector((s) => s.tours);

  useEffect(() => {
    if (isDistrictActivePlan && workspace) {
      if (
        !DISTRICT_PLAN_PERMITTED_WORKSPACES.includes(
          workspace as PlanWorkspaces
        )
      ) {
        history.push(`/plan/${PlanWorkspaces.Dashboard}`);
      }
    }
  }, [isDistrictActivePlan, workspace]);

  const content = useMemo(() => {
    switch (workspace) {
      case PlanWorkspaces.Dashboard: {
        return <TeamPlanDashboard />;
      }
      case PlanWorkspaces.Team: {
        return <TeamingPage />;
      }
      case PlanWorkspaces.MyDistrict: {
        return <DistrictDetails isTeamPlanPage />;
      }
      case PlanWorkspaces.Data: {
        return <CollectDataPage />;
      }
      case PlanWorkspaces.Needs: {
        return <NeedsPage />;
      }

      case PlanWorkspaces.Goals: {
        return tabId === PlanWorkspaces.CustomStrategy ? (
          <CustomStrategy />
        ) : tabId === PlanWorkspaces.AddStrategy ? (
          <AddStrategyForm />
        ) : (
          <StrategicPlan />
        );
      }

      // case PlanWorkspaces.Goals: {
      //   return <GoalsPage />;
      // }

      case PlanWorkspaces.Resources: {
        return <TeamPlanResources />;
      }

      case PlanWorkspaces.StrategyLibrary:{
        return <StrategyLibrary />;
      }
      
      case PlanWorkspaces.Help: {
        return <LearningPage />;
      }
      case PlanWorkspaces.Report: {
        return <PlanReports />;
      }

      case PlanWorkspaces.SurveyQuestions: {
        return <EditSurveyQuestions />;
      }

      case PlanWorkspaces.Welcome: {
        return <AppWelcomePage />;
      }

      case PlanWorkspaces.ManageDeletedData: {
        return <ManageDeletedData />;
      }

      case PlanWorkspaces.SCIWriting: {
        return <NotesPage />; //<SciWritingComponents /> //<SciWritingComponents />;
      }

      // case PlanWorkspaces.CreateNote: {
      //   return <NotesPage/>//<SciWritingComponents /> //<SciWritingComponents />;
      // }

      case PlanWorkspaces.PublishedNotes: {
        return <NotesPage />; //<SciWritingComponents /> //<SciWritingComponents />;
      }

      // case PlanWorkspaces.PublishedNotes: {
      //   return <SciWritingComponentsPublishedNote />;
      // }

      default:
        return null;
    }
  }, [tabId, workspace]);

  return (
    <>
      <div>
        {workspace === PlanWorkspaces.Welcome ||
        workspace === PlanWorkspaces.SCIWriting ||
        workspace === PlanWorkspaces.PublishedNotes ? (
          ""
        ) : (
          <MenuList />
        )}
      </div>
      <div className="flex flex-col flex-grow min-w-0 justify-between bg-gray-100">
        {workspace === PlanWorkspaces.Welcome? (
          ""
        ) : (
          <TopBar
            title={intl.formatMessage({ id: "app.titles.strategic-plan" })}
          />
        )}
        {/* <GetStartedCheckList />  */}
        <DistrictSetupWidget />

        <div
          className={
            workspace === PlanWorkspaces.SCIWriting ||
            workspace === PlanWorkspaces.PublishedNotes
              ? ""
              : "flex-grow min-w-0 p-2 body-container"
          }
        >
          {content}
        </div>
      </div>

      {/* <NotesDialog /> */}
      <ViewActivityContentDialog
        isResourceTab={setupCompleted ? true : false}
      />
      <ShareFeedbackDialog />
    </>
  );
};

export default PlanPage;