import React, { ReactNodeArray } from "react";
import { RootState } from "typesafe-actions";
import { Locale } from "../store/UIState";
import {
  AttachedResource,
  BookmarkType,
  Dataset,
  DatasetMark,
  District,
  Domain,
  DomainPriority,
  DomainPriorityTriageStatus,
  Goal,
  GoalIndicatorTarget,
  LookForItemStatus,
  PlanStrategy,
  PrioritizedNeed,
  PromptByLanguage,
  QuestionTranslates,
  School,
  Strategy,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
  SurveyDeploymentOpenTimePeriod,
  SurveyQuestion,
  TranslatedLanguages,
  UserInvite,
  UserModel,
  UserProfile,
  UserRole,
  UserNotesType,
} from "./index";
import { IconName } from "@blueprintjs/icons";

export type SurveyQuestionExcludeBaseModelType = Omit<
  SurveyQuestion,
  "id" | "created_at" | "updated_at" | "local_code" | "requested_locale_code"
> & { id: string | number };

export type SurveyExcludeCreatorType = Pick<Survey, "id" | "name">;

export type SurveyDeploymentExcludeAuditAndGlobalAndDistrictsType<
  TSurvey extends SurveyExcludeCreatorType | Survey
> = Omit<
  SurveyDeployment<District, TSurvey>,
  "created_at" | "updated_at" | "is_global" | "districts"
>;

export type UserProfileExcludeBaseModel<TDistrict> = Omit<
  UserProfile<TDistrict>,
  "id" | "created_at" | "updated_at"
>;

export type SurveyDeploymentExcludeAuditType<
  TSurvey extends SurveyExcludeCreatorType | Survey
> = Omit<SurveyDeployment<District, TSurvey>, "created_at" | "updated_at">;

export type ConfirmDialogConfig = {
  onConfirm?: () => void;
  text?: string | ReactNodeArray;
  confirmButtonText?: string;
  show?: boolean;
  intent?: "primary" | "danger" | "warning";
  cancelButtonText?: string;
  icon?: "trash" | "info-sign" | "archive";
  onCancel?: () => void;
  canOutsideClickCancel?: boolean;
  isLoadingSelector?: (store: RootState) => boolean | undefined;
  errorSelector?: (store: RootState) => Error | undefined;
  className?: string;
};

export type SelectorOptionType<TValue extends string | number = number> = {
  label: string;
  value: TValue;
};

export type PriorityAreaSectionDetails = {
  surveyName: string;
  selectorOptions: SelectorOptionTypeAdmin[];
  selectedSDs?: number[];
  warning_older_then_a_year?: boolean;
};

export type SelectorOptionTypeAdmin = {
  label: string;
  value: number;
  end_date: string;
  name: string;
  is_older_then_a_year: boolean;
};

export type Timer = {
  page: number | "intro" | "post-complete";
  time_start: string;
  time_end?: string;
};

export type BreadcrumbPropsType = {
  id: number;
  isSelectable?: boolean;
  isVisible?: boolean;
  itemName?: string;
  editableComponent?: React.ReactNode;
};

export enum SurveyReportTabEnum {
  ResponseRates = "response_rates",
  ElementaryStudents = "elementary_students",
  Students = "students",
  SchoolStaff = "school_staff",
  Parents = "parents",
  Comparison = "comparison",
  ClusterTags = "tags",
  Insights = "insights",
}

export type AnswerDistributionByQuartiles = {
  "0to24": number;
  "25to49": number;
  "50to74": number;
  "75to100": number;
};

export type SurveyPermissionsError = {
  survey_name: string;
  school_name: string;
  demographic: SurveyDemographic;
  page_text: PromptByLanguage;
  available_languages: Locale[];
  error?: string;
};

export enum CollectedDemographicDataEnum {
  grade = "grade",
  gender = "gender",
  race = "race",
  language = "language",
  direct_instruction = "direct_instruction",
  remote_learning = "remote_learning",
  staff_role = "staff_role",
  reduced_lunch = "reduced_lunch",
  has_iep = "has_iep",
  english_at_home = "english_at_home",
}

export type SurveyQuestionAnswer = {
  question_id: number;
  answer_index?: number;
  max_answer_index: number;
};

export type SurveyReportRouteParams = {
  districtId?: string;
  schoolId?: string;
  surveyDeploymentId?: string;
  domainId?: string;
  tagId?: string;
  tabId: SurveyReportTabEnum;
};

export type DistrictComparisonRouteParams = {
  schoolId?: string;
  surveyDeploymentId?: string;
  domainId?: string;
  tagId?: string;
  tabId: UserNotesType;
};

export type ResponsesDistribution = {
  label: string;
  percent: number;
  responses: number;
};

export type SurveyResponseRatesKeys =
  | keyof typeof CollectedDemographicDataEnum
  | "overall"
  | "language";

export enum InviteFailReasonEnum {
  EMAIL_EXIST = "email_exist",
  SEND_MAIL_ERROR = "send_mail_error",
}

export type EditedSurveyQuestionsByPage = {
  [page: number]: SurveyQuestionExcludeBaseModelType[];
};

export type QuestionTranslations = {
  [key: string]: TranslatedLanguages<QuestionTranslates> | null;
};

export enum TeamRoleDefinitionRoles {
  Superintendent = "superintendent",
  DistrictClimateCoordinator = "district_climate_coordinator",
  DistrictTeamMembers = "district_team_members",
  TeamChampionOrPrincipal = "team_champion_or_principal",
  TeamChairs = "team_chairs",
  DataCoordinators = "data_coordinators",
  TeamMembers = "team_members",
}

export const TeamRoleDefinitionDict: {
  [key in TeamRoleDefinitionRoles]: "dclt" | "sclt";
} = {
  [TeamRoleDefinitionRoles.Superintendent]: "dclt",
  [TeamRoleDefinitionRoles.DistrictClimateCoordinator]: "dclt",
  [TeamRoleDefinitionRoles.DistrictTeamMembers]: "dclt",
  [TeamRoleDefinitionRoles.TeamChampionOrPrincipal]: "sclt",
  [TeamRoleDefinitionRoles.TeamChairs]: "sclt",
  [TeamRoleDefinitionRoles.DataCoordinators]: "sclt",
  [TeamRoleDefinitionRoles.TeamMembers]: "sclt",
};

export type TeamRoleDefinition = {
  role: TeamRoleDefinitionRoles;
  roleTitle: string;
  responsibilities: string[];
  permissions: string | ReactNodeArray;
};

export type UpsertResourceDialogConfig = {
  show?: boolean;
  onClose?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onSave?: (
    resource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => void;
  resource?: Partial<AttachedResource> & Pick<AttachedResource, "type">;
  isLoadingSelector?: (store: RootState) => boolean | undefined;
  errorSelector?: (store: RootState) => Error | undefined;
};

export type NewGoalState = {
  goal: any;
  mode?: string;
  isEditMode: boolean;
  show: boolean;
  selectedTabId: NewGoalTabs;
  selectedNeeds: PrioritizedNeed[];
  targets: {
    [needId: number]: Pick<
      GoalIndicatorTarget,
      "target_value" | "direction"
    > & { target_date?: Date; isRecommendation?: boolean };
  } | GoalIndicatorTarget[] | any[];
  goalStatement: string;
  desiredOutcome: string;
  strategies: Strategy[];
  indicatorGroups: any[];
  errors: any[];
  canNavigateToStep2: boolean;
  canNavigateToStep3: boolean;
  workingCopy?: Goal | any;
  selectedGoal?: Goal | any;
  latestAddedByPusher:boolean;
};

export enum NewGoalTabs {
  ChooseNeeds = "select-priority-domain",
  GoalStatement = "set-indicator-targets",
  TargetOutcomes = "describe-desired-outcome",

  // ChooseNeeds = "choose-priority-area",
  // ChooseNeeds = "choose-needs",
  // GoalStatement = "goal-statement",
  // TargetOutcomes = "target-outcomes",
  // FindStrategies = "find-strategies",
}

export type FlaggedNeedType = {
  domain: Domain;
  planStrategies: PlanStrategy[];
  datasets: (DatasetMark & { needs?: PrioritizedNeed })[];
};

export type LookForItemStatusExcludeBaseModel = Omit<
  LookForItemStatus,
  "id" | "created_at" | "updated_at"
>;

export type AddPlanStrategyDialogType = {
  show?: boolean;
  goal?: Goal;
  domains?: Domain[];
  strategy?: Strategy;
  onConfirm?: (planStrategy: PlanStrategy) => void;
  showPlanStrategies?: boolean;
  loadingSelector?: (store: RootState) => boolean | undefined;
  errorSelector?: (store: RootState) => Error | undefined;
};

export type AddStrategyToGoalDialogType = {
  show?: boolean;
  strategy?: Strategy | PlanStrategy;
  goal?: Goal;
  onConfirm?: (planStrategy: PlanStrategy) => void;
};

export type ViewDataSetDialogType = {
  show?: boolean;
  dataset?: Dataset;
  addingByDefault?: boolean;
  onClose?: Function;
};

export type UserViewedPlan = {
  userId: number;
  planId: number;
};

export type OpenTimePeriod<TSchool extends School | number = number> = Pick<
  SurveyDeploymentOpenTimePeriod<TSchool>,
  "start_date" | "end_date" | "created_at" | "demographic" | "school"
> & {
  id?: number;
  disabled?: boolean;
};

export enum YesNoAnswerOptions {
  Yes = 1,
  No = 0,
}

export enum CustomAnswerOptions {
  PreferNotToAnswer = 2,
}

export type SimpleAnswerOptions = YesNoAnswerOptions | CustomAnswerOptions;

export enum StaffRoleQuestions {
  ADMIN_SUPERVISOR_TEACHER_SPECIAL_SERVICES = 0,
  SCHOOL_AIDE_PARAPROFESSIONAL_CLERICAL_CUSTODIAL_ETC = 1,
}

export const MAX_DISCREPANCY = 0.5;
export const NOT_APPLICABLE_KEY = -1;
export const DOMAIN_ANSWER_MIN_VALUE = 1;
export const LOCAL_INSTANCE_START_KEY = -1;

export type DistrictSetupTask = {
  id: string;
  text: string;
  checked?: boolean;
  order: number;
  show?: boolean;
  onClick?: Function;
};


export enum DistrictSetupTaskKey {
  Review = "review",
  FamiliarizeWithSccp = "familiarize_with_sccp",

  DesignateDistrictClimateCoordinator = "designate_climate_coordinator",
  CommunicateWithPrincipals = "communicate_with_principals",

  RegisterSchoolsAndInvitePrincipals = "register_schools_and_invite_principals",
  RegisterYourSchool = "register_your_school",
  InviteOtherMembers = "invite_other_members",
  LearnAboutTeamDevelopment = "learn_about_team_development",
  InviteSchoolStaff = "invite_school_staff",
  SetupDistrictCalendar = "setup_district_calendar",
  SetupSchoolCalendar = "setup_school_calendar",
  InviteSchoolStaffPrincipal = "InviteSchoolStaffPrincipal"
}

export type TourDictionary = {
  hidden?: boolean;
  lastTourWatched?: boolean;
  makingProgressTourTimeWatched?: Date;
  softAddDistrictStaff?: boolean;
} & {
    [key in DistrictSetupTaskKey]?: boolean;
  } & {
    [DistrictSetupTaskKey.InviteSchoolStaff]?: {
      [schoolId: number]: {
        completed: boolean;
        softAddStaff?: boolean;
      };
    };
    [DistrictSetupTaskKey.SetupSchoolCalendar]?: {
      [schoolId: number]: boolean;
    };
  };

export enum PlanWorkspaces {
  Dashboard = "dashboard",
  Team = "team",
  Calendar = "calendar",
  Needs = "needs",
  Data = "data",
  SurveyQuestions = "survey-questions",
  Report = "report",
  Goals = "goals",
  AddStrategy = "add-strategy",
  CustomStrategy = "custom-strategy",
  Resources = "resources",
  StrategyLibrary = "strategy-libary",
  Help = "help",
  MyDistrict = "my-district",
  ManageDeletedData = "deleted-data",

  Welcome = "welcome",
  AllNotes = "notes",
  SCIWriting = "sci-writing",
  CreateNote = "create-note",
  PublishedNotes = "published-notes",
}

export enum ActivityTagEnum {
  TEAM = "team",
  NEEDS = "needs",
  DATA = "data",
  GOALS = "goals",
  LEARNING_NETWORK_EVENTS = "learning",
  RESOURCES="resources"
}

export type PlanWorkspaceButton = {
  icon?: IconName;
  title: string;
  workspace_keys: PlanWorkspaces[];
  disabled?: boolean;
  popoverMessage?: string;
  className?: string;
  dataTour?: string;
  onClick?: Function;
  hidden?: boolean;
  category?: string;
  svgIcon?: any;
};

export const YEARS_OFFSET = 5;

export type LockType = "district" | "soft";
export type WorkspaceAvailabilityDictionary = {
  [key in PlanWorkspaces]?: {
    available?: boolean;
    lockType?: LockType;
  };
};

export type InsightSubgroupInfoDialogType = {
  show?: boolean;
  subgroupName?: string;
  domainsCortege?: [number, number][];
  itemsCortege?: [number, number][];

  domains_avg?: {
    [domainId: number | string]: number;
  };
  questions_avg?: {
    [questionId: number | string]: number;
  };

  domains?: Domain[];
  questions?: SurveyQuestion[];

  demographic?: SurveyDemographic;
  groupBy?: {
    key: string;
    value: string;
  };
};

export enum StrategicPlanTabEnum {
  Goals = "goals",
  StrategyCards = "strategy-cards",
  CoverageMatrix = "coverage-matrix",
  StrategicPlanResources = "strategic-plan-resources",
}

export enum StrategicPlanPageTabEnum {
  Progress = "progress",
  Planning = 'planning',
  Coverage = "coverage",
  Resources = "resources",
  Strategies = "strategies",
}

// export enum LearningPageTabEnum {
//   SelfDirectedLearningModules = "self-directed-learning-modules",
//   LearningNetworkModules = "learning-network-modules",
// }

export enum TeamPlanResourcesTabEnum {
  // FullStrategyLibrary = "full-strategy-library",
  Resources = "resource",
  TeamDevelopment = "team-development",
}

export enum UserType {
  User = "user",
  Invite = "invite",
}

export type UserInfo = {
  id?: number;
  invite_id?: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  role: UserRole;
  role_name: string;
  created_at: string;
  user_agreement?: string;
  type: UserType;
  district_name?: string;
  is_active?: boolean;
  is_single_school_district?: boolean;
  is_dcc?: boolean;
  schools: string[];
  invite_status_name?: string;
  invite_status?: string;
  bounce_status?: string,
  original: UserModel | UserInvite;
};

export const studentGroupByOrder: {
  [key in CollectedDemographicDataEnum]?: number | undefined;
} = {
  [CollectedDemographicDataEnum.grade]: 0,
  [CollectedDemographicDataEnum.race]: 1,
  [CollectedDemographicDataEnum.gender]: 2,
  [CollectedDemographicDataEnum.english_at_home]: 3,
};

export const parentGroupByOrder: {
  [key in CollectedDemographicDataEnum]?: number | undefined;
} = {
  [CollectedDemographicDataEnum.race]: 0,
  [CollectedDemographicDataEnum.has_iep]: 1,
  [CollectedDemographicDataEnum.reduced_lunch]: 2,
};

export const CollectedDemographicDataBgColors: {
  [key in CollectedDemographicDataEnum]: string;
} = {
  [CollectedDemographicDataEnum.grade]: "#E6E6E6",
  [CollectedDemographicDataEnum.gender]: "#595959",
  [CollectedDemographicDataEnum.race]: "#B0ABAB",
  [CollectedDemographicDataEnum.direct_instruction]: "",
  [CollectedDemographicDataEnum.remote_learning]: "",
  [CollectedDemographicDataEnum.staff_role]: "#58696E",
  [CollectedDemographicDataEnum.reduced_lunch]: "",
  [CollectedDemographicDataEnum.has_iep]: "#58697D",
  [CollectedDemographicDataEnum.english_at_home]: "#F2F2F2",
  [CollectedDemographicDataEnum.language]: "",
};

export type ItemLevelInfoAndBookmarksDialogType = {
  domain: Domain;
  demographic: SurveyDemographic;
  meanScore?: number;
  marksCount: {
    [key in BookmarkType]: number;
  };
};

export type PrioritizeAndDoNotPrioritizeDomainDialogType = {
  domain: Domain;
  demographic: SurveyDemographic;
  meanScore?: number;
  prioritized?:boolean;
  marksCount: {
    [key in BookmarkType]: number;
  };
};

export type DomainDemographicTriageStatuses = {
  [key in SurveyDemographic]?: DomainPriorityTriageStatus | null;
} & {
  archived: boolean;
  domainPriorities: DomainPriority[];
};

export type DeepPartial<T> = T extends object
  ? {
    [P in keyof T]?: DeepPartial<T[P]>;
  }
  : T;