import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import DetailsStrategyDialog from "../../strategies/details-strategy-dialog/DetailsStrategyDialog";
import DashboardCard from "../DashboardCard";
import StrategicPlanWidgetBody from "./StrategicPlanWidgetBody";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import chartImg from "../../../../static/images/Mask-group.png";
import trophy from "../../../../static/images/trophy.svg";
import { Card, Spinner } from "@blueprintjs/core";
import CustomArrow from "../../../climate-overview-carousel/CustomArrow";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { getPlanStrategies } from "../../../../store/plan-strategies/actions";
import { getGoalsByPlan } from "../../../../store/goals/actions";
import { Doughnut } from "react-chartjs-2";
import { Domain, Goal, GoalIndicatorTarget, Plan, PlanStrategy, SurveyDeploymentStateEnum } from "../../../../types";
import { AuthRoutes } from "../../../../App";
import { generatePath, useHistory } from "react-router";
import { filterStrategiesByDomainAndSearchString } from "../../utils";
import Chart from "chart.js";


type OwnProps = {
  activePlanId?:number
  isDistrictActivePlan?:boolean

};

type Props = OwnProps;

const StrategicPlanWidget: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const activePlanId = useSelector((s) => {return props.isDistrictActivePlan ? props.activePlanId : s.plans.activePlan?.id});
  const activePlan = useSelector((s) => s.plans.activePlan);
  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const goals: Goal[] = useSelector((s) => s.goals.goals);
  const goalsLoading = useSelector((s) => s.goals.loading.getGoals);
  const goalsError = useSelector((s) => s.goals.errors.getGoals);
  const [displayedPlanStrategies, setDisplayedPlanStrategies] = useState<
        any
    >([]);
  useLoading({ loading: goalsLoading, error: goalsError });

  const planStrategies =  useSelector((s) => s.planStrategies.planStrategies);
  const strategiesLoading = useSelector(
    (s) => s.planStrategies.loading.getPlanStrategies
  );
  const strategiesError = useSelector(
    (s) => s.planStrategies.errors.getPlanStrategies
  );

  const [currentSlide, setCurrentSlide] = useState<Number>(0);

  const  mostRecentDeployment = useMemo(()=>
  { return surveyDeployments.find((sd) => {
    if (
      sd.state === SurveyDeploymentStateEnum.READY &&
      sd.is_deleted === false
    ) {
      return sd;
    }
  })},[surveyDeployments])



  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current:any, next:any) => setCurrentSlide(next),
    prevArrow: <CustomArrow isPrev disabled={currentSlide === 0} />,
    nextArrow: <CustomArrow disabled={currentSlide === goals.length - 1}/>,
    adaptiveHeight: true, // Adjust height based on content
    centerMode: true, // Center slides properly
    centerPadding: "0", // Remove extra padding
    variableWidth: false, // Ensure consistent slide width
  };

  const getPlanStrategiesRequest = () => {
    if (activePlanId) {
      dispatch(getPlanStrategies.request(activePlanId));
      dispatch(getGoalsByPlan.request(activePlanId));
    }
  };
  useEffect(() => {
    getPlanStrategiesRequest();
  }, [activePlanId]);
  


  const calculateGoalStats = (
    goal: Goal
  ): { Higher: number; Lower: number; Same: number } => {
    if (!goal?.targets) {
      return { Higher: 0, Lower: 0, Same: 0 };
    }

    const targets = goal.targets as GoalIndicatorTarget[];

    return {
      Higher: targets.filter((tar) => tar.trend_against_baseline === "Higher")
        .length,
      Lower: targets.filter((tar) => tar.trend_against_baseline === "Lower")
        .length,
      Same: targets.filter((tar) => tar.trend_against_baseline === "Same")
        .length,
    };
  };

  function countMatchingDomains(selectedDomains:string[], PlanStrategies:PlanStrategy[]) {
   
  
    const strategiesWithMatch = PlanStrategies?.filter((item:PlanStrategy) =>
      item.strategy?.domains?.some((domain) =>
        selectedDomains.includes(domain.name)
      )
    );
    return strategiesWithMatch.length;
  
  }

  const updateDisplayedPlanStrategies = useCallback((goal:Goal) => {

    if (goal && goal?.selected_domains?.length) {
      const strategies = countMatchingDomains( goal.selected_domains.map((et:any) => et.name) ?? [],planStrategies)
      return strategies;
    } else {
      return 0;
    }
  },[planStrategies]);

  const options = {
    cutout: "70%", // Creates the inner circle

    legend: {
      display: false, // Hide the legend
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
    interaction: {
      mode: 'nearest',  // This disables hover interaction.
      intersect: false, // Set to false to prevent hover effect when the mouse is outside of any slice.
    },
    events: [],
    animation: {
      onComplete: function (this: Chart) {
        const chartInstance = this;
        const ctx = chartInstance.ctx;
        if (!ctx) return;

        ctx.font = "bold 12px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle ='black';

        chartInstance?.data?.datasets?.forEach((dataset: any, i: number) => {
          const meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach((element: any, index: number) => {
            if (dataset.data[index] > 0) {
              const data = dataset.data[index];
              const position = element.tooltipPosition();
              ctx.fillText(data, position.x, position.y - 1); // Position text above each segment
            }
          });
        });
      },
    },
  };

  function hexToRgba(hex:string|any, opacity:number) {
    // Remove the leading '#' if it exists
    hex = hex?.replace('#', '');

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);


    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const opacity = 0.1;

  return (
    <>
      {/* <DashboardCard
        title={intl.formatMessage({ id: "app.titles.strategic-plan" })}
        body={<StrategicPlanWidgetBody />}
        centeredBody
      />
      <DetailsStrategyDialog /> */}
      
      <div className={`py-2 ${props?.isDistrictActivePlan?"":"px-4"}`}>
        <Grid container spacing={4} columns={12}>
          {goalsLoading ? (
            <Grid item xs={12} sm={12} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <Spinner size={50} intent="primary" className="text-center" />
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
               {goals?.length ? (
                 
                 <Slider {...settings} className="progress-slider">
                 {goals.filter((goal:Goal) => goal?.targets?.length).map((goal:Goal, index) => (
                   <div key={index} className="slide-container">
                     <Grid container spacing={3}>
                       <Grid item xs={12}>
                         <div className="font-bold  text-xl mt-3">
                           {intl.formatMessage({
                             id: "app.dashboard.strategic-plan-progress-widget.title",
                           })}
                         </div>
                         <h6 className="sliderTitle pt-2">{intl.formatMessage({id:"app.titles.goal"})}: {goal?.statement}</h6>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                        {!goalsLoading && goals?.length ? (
                           <p className="administration-text">
                            {intl.formatMessage({id:"app.title.strategic-plan-progress-widget.baseline-data"})}{" "} 
                             <span>
                               {goal?.survey_deployment?.deployment_name.slice(
                                 0,
                                 goal?.survey_deployment?.deployment_name.indexOf("(")
                               )}
                             </span>{" "}
                             {goal?.survey_deployment?.deployment_name.slice(
                               goal?.survey_deployment?.deployment_name.indexOf("(")
                             )}
                           </p>
                         ) : (
                           ""
                         )}
                         <div className="domain-list">
                           <p className="domain-count">
                             {intl.formatMessage({
                               id: "app.titles.domains",
                             })}{" "}
                             <span>({goal?.selected_domains?.length})</span>
                           </p>
                           <ul className="scroll-plan-progress-widget-domains">
                             {goal?.selected_domains?.map((sd) => (
                                <li style={{borderLeft:`9px solid ${sd.color_hex}`,background:hexToRgba(sd.color_hex, opacity)}} key={sd.name}>{sd?.name}</li>
                             ))}
                           </ul>
                           <p className="domain-strategies">
                             You have{" "}
                            
                               {updateDisplayedPlanStrategies(goal)}{" "}
                               {intl.formatMessage({ id: "app.goals.card.aligned-strategies" })}
                            
                          
                           </p>
                         </div>
                       </Grid>
                       {!goal?.targets?.some(
                               (tar) => tar?.trend_against_baseline !== null
                             ) || goal?.targets?.length === 0 ? (
                               <Grid item xs={12} sm={12} md={6}>
                                 <h6 className="sliderTitle">
                                   {intl.formatMessage({
                                     id:
                                       "app.dashboard.strategic-plan-progress-widget.no-progress-monitoring-data.heading",
                                   })}
                                 </h6>
                                 <Card className="leadershipTeamCard progress-monitor no-progress">
                                   <div>
                                     <p>
                                       {intl.formatMessage({
                                         id:
                                           "app.dashboard.strategic-plan-progress-widget.no-progress-monitoring-data.body",
                                       })}
                                     </p>
                                   </div>
                                 </Card>
                               </Grid>
                             ):(<Grid item xs={12} sm={12} md={6} className="pr-3" style={{paddingTop:"0px"}}>
                         <h6 className="sliderTitle mb-2">{intl.formatMessage({id:"app.strategic-plan-widget.progress-on-goals.title"})}</h6>
                         <p className="administration-text">{intl.formatMessage({id:"app.titles.dashboard.strategic-plan-progress-widget.latest-generated-data"})} {mostRecentDeployment?.deployment_name}</p>
                         <div className="progress-goals flex items-center">
                           <div
                             style={{
                               width: "220px",
                               height: "150px",
                               position: "relative",
                             }}
                           >
                             <Doughnut
                               data={{
                                 labels: ["Higher", "Lower", "Same"],
                                 datasets: [
                                   {
                                     data: [
                                       calculateGoalStats(goal).Higher,
                                       calculateGoalStats(goal).Lower,
                                       calculateGoalStats(goal).Same,
                                     ],
                                     backgroundColor: ["#60b952", "#ffb329", "#d9d9d9"],
                                     borderWidth: 0,
                                   },
                                 ],
                               }}
                               options={options}
                             />
                             <div
                               style={{
                                 position: "absolute",
                                 top: "35%",
                                 left: "50%",
                                 transform: "translate(-50%, -50%)",
                                 textAlign: "center",
                               }}
                             >
                               <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                                 {goal?.targets?.length || 0}
                               </div>
                               <div style={{ fontSize: "12px" }}>
                                 {intl.formatMessage({
                                   id: "app.goals.new-goal.indicator",
                                 })}
                                 s
                               </div>
                             </div>
                           </div>
                           <div style={{ textAlign: "left" }}>
                             <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                               <li>
                                 <span className="rectangle lower-color"></span>
                                 Current mean <strong>Higher</strong> than the baseline
                               </li>
                               <li>
                                 <span className="rectangle higher-color"></span>
                                 Current mean <strong>Lower</strong> than the baseline
                               </li>
                               <li>
                                 <span className="rectangle same-color"></span>
                                 Current mean <strong>Same</strong> as the baseline
                               </li>
                             </ul>
                             <h5>
                               <img src={trophy} alt="trophy icon" className="mr-2" />
                               {
                                 goal?.targets?.filter((tr) => tr.target_exceeded)?.length
                               }{" "}
                               {intl.formatMessage({
                                 id: "app.dashboard.strategic-plan-progress-widget.target-indicators-met-exceed",
                               })}
                             </h5>
                           </div>
                         </div>
                         {props?.isDistrictActivePlan? "" :<div
                             style={{ 
                               fontSize: "16px",
                               fontWeight: "bolder",
                               color: "#195A94",
                               textDecoration:"underline",
                               lineHeight: "25px",
                               display: "block",
                               textAlign: "right",
                               marginTop: "15px",
                              }} 
                              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "none")}
                             onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "underline")}                       
                            onClick={() =>
                                 history.push(
                                   generatePath(AuthRoutes.YourPlan, {
                                     workspace: "goals",
                                     // tabId: "planning",
                                   } as any)
                                 )
                               }>
                                   {intl.formatMessage({id:"app.titles.view-more-details"})}
                           </div>}
                       </Grid>)}
                     </Grid>
                   </div>
                 ))}
               </Slider>
              
               ) : (
                 <>
                 <div className="font-bold text-xl mt-3 mb-2">
                           {intl.formatMessage({
                             id: "app.dashboard.strategic-plan-progress-widget.title",
                           })}
                         </div>
             
                   <div>
                     <Grid item xs={12} sm={12} md={12}>
                       <Card className="leadershipTeamCard progress-monitor">
                         <div>
                           <h6>
                             {intl.formatMessage({
                               id:
                                 "app.titles.no-goals-progress-monitoring.head",
                             })}
                           </h6>
                           <p>
                             {intl.formatMessage({
                               id:
                                 "app.titles.no-goals-progress-monitoring.body",
                             })}
                           </p>
                         </div>
                       </Card>
                     </Grid>
                   </div>
                 </>
               )}
              
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default StrategicPlanWidget;