import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import {
  UserNotesType,
} from "../../../types";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";
import StrategiesResourceList from "../../pages/strategic-plan/strategies/strategies-list/StrategiesResourceList";

type OwnProps = {};

type Props = OwnProps;

const StrategyLibrary: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

 const isDistrictActivePlan = useSelector(isDistrictPlan);


  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.strategy-libary" })}</title>
      </Helmet>

      <div className="teampage_container">
        <WorkspaceHeader
          title={intl.formatMessage({ id: "app.titles.strategy-libary" })}
          userNotesType={
            isDistrictActivePlan
              ? UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE
              : UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE
          }
        />

        <div className="teampage_container__tab">
        <StrategiesResourceList />
        </div>
      </div>
    </>
  );
};

export default StrategyLibrary;