import Axios, { AxiosRequestConfig } from "axios";
import { API_ROOT } from "../config";
import {
  LoginRequest,
  RegisterUserRequest,
  RestorePasswordRequest,
} from "./types";
import { AuthenticatedUserModel, ShareFeedbackModel, UserProfile } from "../../types";

export const login = (request: LoginRequest): Promise<void> => {
  return Axios.post(`${API_ROOT}/auth/login/`, request);
};

export const logout = (): Promise<void> => {
  function getCookie(name:string) {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  }

  return Axios.post(`${API_ROOT}/auth/logout/`,{csrf:getCookie("csrftoken")});
};

export const resetPassword = (email: string): Promise<void> => {
  return Axios.post(`${API_ROOT}/password-reset/`, { email: email });
};

export const restorePassword = (
  request: RestorePasswordRequest,
  code: string
): Promise<AuthenticatedUserModel> => {
  return Axios.post(`${API_ROOT}/restore-password/${code}/`, request);
};

export const registerUser = (
  request: RegisterUserRequest,
  inviteCode: string
): Promise<AuthenticatedUserModel> => {
  return Axios.post(`${API_ROOT}/invite/accept/${inviteCode}/`, request);
};

export const getAuthenticatedUser = (): Promise<AuthenticatedUserModel> => {
  return Axios.get(`${API_ROOT}/users/me/`);
};


export const uploadProfileImage = (
  file: File
): Promise<Pick<UserProfile<number>, "image_url">> => {
  const formData = new FormData();
  formData.append("image", file);

  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return Axios.put(`${API_ROOT}/profile/image/upload/`, formData, config);
};


export const removeProfileImage = (user: any): Promise<void> => {
  
  return Axios.get(`${API_ROOT}/profile/image/upload/remove_profile_image`,user);

};