import React, { useState } from "react";
import { useIntl } from "react-intl";
// import Select from "react-select";
// import { Button, Dialog } from "@blueprintjs/core";
import GenericCollapse from "../../common/GenericCollapse";
import { SurveyDeploymentYear } from "../../../types";

import { Dialog, DialogActions, DialogContent, IconButton  ,InputLabel,Select} from "@material-ui/core";
import { Button, Divider, FormControl, MenuItem, styled } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

import CloseIcon from "@mui/icons-material/Close";


type OwnProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  distinctSurveyDeployments: SurveyDeploymentYear[];
  distinctSchoolYear: string[];
  handleSelectChange: (
    value: string | SurveyDeploymentYear,
    comparisonReportType: string
  ) => void;
};

type Props = OwnProps;



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }, "& .MuiPaper-root": {
    width: "60%", // Set the desired width here
    maxWidth: "100%", // Adjust max-width to make it responsive
  },
}));


const ComparisonReportDialog: React.FC<Props> = (props) => {
  const intl = useIntl();
  const {
    isOpen,
    setOpen,
    distinctSurveyDeployments,
    distinctSchoolYear,
    handleSelectChange,
  } = props;

  const SCHOOL_BY_SAME_DEPLOYMENT = "school-by-same-deployment";
  const SCHOOL_BY_MOST_RECENT_SURVEY = "school-by-most-recent-survey";
  const [
    selectedDeploymentValue,
    setSelectedDeploymentValue,
  ] = useState<SurveyDeploymentYear>();

  const [selectedSchoolValue, setSelectedSchoolValue] = useState<string>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeploymentViewReport = () => {
    if (selectedDeploymentValue)
      handleSelectChange(selectedDeploymentValue, SCHOOL_BY_SAME_DEPLOYMENT);
  };

  const handleSchoolViewReport = () => {
    if (selectedSchoolValue)
      handleSelectChange(selectedSchoolValue, SCHOOL_BY_MOST_RECENT_SURVEY);
  };

  return (
    <>
      <BootstrapDialog
        title={intl.formatMessage({
          id: "app.survey-deployment.comparison-reports",
        })}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="lg"
      >
        <div className="flex justify-between items-center">
          <div className="text-lg font-medium  ml-5">
            {intl.formatMessage({
              id: "app.survey-deployment.comparison-reports",
            })}
          </div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Divider />

        <DialogContent className="content-padding">
          <div className="">
            {intl.formatMessage({
              id: "app.survey-deployment.comparison-report-dialog",
            })}
          </div>

          <h4 className="mt-5 font-bold">
            {intl.formatMessage({
              id: "app.survey-deployment." + SCHOOL_BY_SAME_DEPLOYMENT,
            })}
          </h4>
          <div className="mb-4 mt-2">
            {intl.formatMessage({
              id: "app.survey-deployment.school-by-same-deployment-info",
            })}
          </div>
            
          <div style={{ display: "flex", justifyContent: "center" }}>
            
            <FormControl >
              <InputLabel id="select-deployment-label">
                {intl.formatMessage({id:"app.titles.data-collection-reports.select-survey-deployment-dropdown"})}
              </InputLabel>
              <Select
                placeholder="Select Survey Deployment"
                variant="outlined"
                labelId="select-deployment-label"
                value={selectedDeploymentValue ? JSON.stringify(selectedDeploymentValue) : ""}
                onChange={(event) => {
                  const value = JSON.parse(event.target.value as string); ;
                  
                  setSelectedDeploymentValue(value);
                }}
                label="Select Deployment"
                className="h-10"
                style={{width:"384px"}}
              >
                {distinctSurveyDeployments?.map((sd: SurveyDeploymentYear) => (
                  <MenuItem
                    key={`${sd.deployment}-${sd.administration}`}
                    value={JSON.stringify(sd)}
                  >
                    {`${sd.deployment} Administration #${sd.administration}`}
                  </MenuItem>
                  // <></>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>

        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                        <WarningIcon style={{ color: 'orange', marginRight: '8px' , marginLeft: '20px' }} />
                        <span style={{ color: 'black'}}>
                        {intl.formatMessage({
              id: "app.survey-deployment.veiw-comparison.warning.notes",
            },
            {
             i: (chunks) => <i>{chunks}</i>,
            }
            )}
                        </span>
                    </div>

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <button
            // autoFocus
            className="new-nj-btn mb-5"
            onClick={handleDeploymentViewReport}
          >
            {intl.formatMessage({
              id: "app.survey-deployment.veiw-comparison-report",
            })}
          </button>
        </DialogActions>
      </BootstrapDialog>
      
    </>
  );
};

export default ComparisonReportDialog;