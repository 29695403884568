import React, { FunctionComponent, useMemo } from "react";
import { Button, Spinner, Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import moment from "moment";
import UpcomingActivityTag from "./UpcomingActivityTag";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { UserNotesType } from "../../../../types";

import { useDispatch, useSelector } from "react-redux";
import changeProcess from "../../../../static/images/School-Climate-Change-Process-Graphic.png";
import { showLearnAboutSccpAndDcltdDialog } from "../../../../store/team-members/actions";

type OwnProps = {
  title?: string;
};

type Props = OwnProps;

const NUMBER_OF_MONTHS = 2;
const MAX_ITEMS = 6;

const UpcomingEvents: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const {
    title = intl.formatMessage({
      id: "app.titles.school-climate-change-process",
    }),
  } = props;

  const meetings = useSelector((s) => s.meetings.meetings);
  const loading = useSelector((s) => s.meetings.loading.getMeetings);
  const error = useSelector((s) => s.meetings.errors.getMeetings);
  useLoading({ loading, error });

  const upcomingMeetings = useMemo(() => {
    return meetings
      .filter(
        (m) =>
          moment(m.date).isBetween(
            moment(),
            moment(m.date).add(NUMBER_OF_MONTHS, "months"),
            "day",
            "[]"
          ) ||
          moment(m.end_date).isBetween(
            moment(),
            moment(m.end_date).add(NUMBER_OF_MONTHS, "months"),
            "day",
            "[]"
          )
      )
      .sort((a, b) => {
        return new Date(b.date).getTime() > new Date(a.date).getTime() ? -1 : 1;
      });
  }, [meetings]);

  return (
    <div className="flex-1">
    <div className="text-xl font-bold text-blue-600 mb-2">
      {title}
    </div>
    {loading ? (
      <Spinner intent="primary" className="mt-2" />
    ) 
    : ( <Tooltip
      content={"Click to enlarge"}
      className=" w-full z-50"
      targetClassName=" w-full"
      position={"bottom"}
    >  
      <div className="text-center" >
      <img src={changeProcess} alt="Change Process Image" className="change-process-img w-60 h-56 cursor-pointer" onClick = {()=>dispatch(showLearnAboutSccpAndDcltdDialog(true))}/>
      </div>
      </Tooltip>
    )}
  </div>
);

};

export default UpcomingEvents;